<template>
  <div v-if='list.length && checkbox.length'>
    <div class="allPanel">
      <van-checkbox v-model="chooseAll" @click='changeAll'>
        <div class="ckItem f_flex f_a_c">
          全选
        </div>
      </van-checkbox>
    </div>
    <van-collapse v-model="act">
      <van-collapse-item 
        :ref='`collapse_${index}`'
        v-for='(item, index) in getListFromWord' 
        :key='index'
        :name="index">
        <template #title>
          <div class="f_flex f_a_c">
            <div class="checkBoxItem" @click.stop='pickItem(item, index)' v-if='!checkbox[index].length'></div>
            <i v-else  @click.stop='pickItem(item, index)' class="iconfont checkIcon icon-check" :class='[checkbox[index].length === item.departmentUserList.length ? "check_2" : "check_1"]'></i>
            <div>{{item.department.departmentName}} <span class="theme_c">({{checkbox[index].length}}/{{item.departmentUserList.length}})</span></div>
          </div>
        </template>

        <div class="content" v-if='item.departmentUserList'>
          <van-checkbox-group  v-for='(ii, kk) in item.departmentUserList' :key='kk' v-model="checkbox[index]">
            <div class="ckItem f_flex f_a_c" v-if='ii.existStatus === 1'>
              <i class="iconfont checkIcon icon-check"></i>
              <div class="f_flex f_a_c">
                <div class="avatar">
                  <img class="img" v-if='ii.avatar' :src="ii.avatar" alt="">
                </div>
                {{ii.userName}}{{ii.selfStatus === 1 ? '(自己)' : ''}}
              </div>
            </div>
            <van-checkbox v-else :name="ii.userId" @click="emitChange">
              <div class="ckItem f_flex f_a_c">
                <div class="avatar">
                  <img class="img" v-if='ii.avatar' :src="ii.avatar" alt="">
                </div>
                {{ii.userName}}{{ii.selfStatus === 1 ? '(自己)' : ''}}
              </div>
            </van-checkbox>
          </van-checkbox-group>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Checkbox, CheckboxGroup } from 'vant';
import groupApi from '@/api/group'
import {mapActions} from 'vuex'

export default {
  components: {
    'van-collapse': Collapse,
    'van-collapse-item': CollapseItem,
    'van-checkbox': Checkbox,
    'van-checkbox-group': CheckboxGroup
  },
  props: {
    companyId: {
      type: String,
      default: '0'
    },
    word: {
      type: String,
      default: ''
    }
  },
  data: ()=> ({
    chooseAll: false,
    act: [], // 折叠面板
    checkbox: [],
    list: [], // [{department: {}, userList: {}}]
  }),
  computed: {
    pick() {
      const list = this.list
      const check = this.checkbox
      const arr = []
      let count = 0
      check.forEach((item, index) => {
        const listItem = list[index].departmentUserList.filter(item => item.existStatus !== 1)
        if (item && item.length) {
          item.forEach((ii, kk) => {
            const b = listItem.find(li => li.userId === ii)
            if (b) {
              const childItem = {
                index: kk,
                item: ii,
                originItem: b
              }
              arr[index] = arr[index] ? arr[index].concat(childItem) : [childItem]
              count++
            }
          });
        }
      });
      return {
        pick: arr,
        count: count
      }
    },
    getListFromWord() {
      const word = new RegExp(this.word.trim())
      const list = this.list
      if (!this.word) return this.list;
      const arr = []
      list.forEach(item => {
        const department = item.department.departmentName
        if (department && department.match(word)) {
          arr.push(item)
        } else {
          const t = item.departmentUserList.filter(ii => {
            return ii.userName && ii.userName.match(word)
          })
          const obj = {
            department: item.department,
            departmentUserList: t
          }
          arr.push(obj)
        }
      });
      return arr
    }
  },
  watch: {
    checkbox(n) {
      const list = this.list
      let b = true
      n.forEach((item, index) => {
        if (item.length < list[index].departmentUserList.length) {
          b = false
        }
      })
      this.chooseAll = b
    },
    companyId(n) {
      this.getList(n).then(res => {
        this.list = res
        this.initList()
      })
    }
  },
  methods: {
    ...mapActions([
      'set_group_contact'
    ]),
    // 获取数据
    getList(id) {
      return new Promise((resolve) => {
        groupApi.queryDepartmentUserList({
          companyId: id,
          groupId: this.$route.query.groupId
        }).then(res => {
          if (res.data) {
            resolve(Array.from(res.data.departmentUserList))
          }
        })
      })
    },
    // 全选某部门
    pickItem(item, index) {
      const i = this.checkbox[index] || []
      const list = i.length < item.departmentUserList.length ? this.getItemAll(index) : this.getInitPartCK(index)
      this.$set(this.checkbox, index, list)
      this.emitChange()
    },
    // 子项初始化
    getInitPartCK(index) {
      const list = this.list[index].departmentUserList
      const arr = []
      list.forEach(ii=> {
        if (ii.existStatus == 1) {
          arr.push(ii.userId)
        }
      })
      return arr
    },
    // 子项全选
    getItemAll(index) {
      const arr = []
      const list = this.list[index].departmentUserList
      list.forEach(item => {
        arr.push(item.userId)
      });
      return arr
    },
    // 全选全部
    changeAll() {
      const e = this.chooseAll
      const list = this.list
      if (e) {
        list.forEach((item, index) => {
          this.$set(this.checkbox, index, this.getItemAll(index))
        });
      } else {
        list.forEach((item, index) => {
          this.$set(this.checkbox, index, this.getInitPartCK(index))
        });
      }
      this.emitChange()
    },
    // 注册事件
    emitChange() {
      const check = this.checkbox
      const pick = this.pick
      this.$emit('change', {
        check: check,
        pick: pick
      })
      this.set_group_contact({
        check: check,
        pick: pick
      })
    },
    // 用于外部调用移除某项
    removeItem(item, index) {
      this.checkbox[index].splice(item.index, 1)
      this.$set(this.checkbox, index, this.checkbox[index])
      this.emitChange()
    },
    // 初始化已选中的部分
    initList() {
      let arr = []
      let list = this.list
      list.forEach((_, index) => {
        arr[index] = this.getInitPartCK(index)
        this.act.push(index)
      });
      this.checkbox = arr
    }
  },
  // mounted() {
  //   this.getList(this.companyId).then(res => {
  //     this.list = res
  //     this.initList()
  //   })

  //   this.$route.meta.change = ()=> {
  //     this.getList(this.companyId).then(res => {
  //       this.list = res
  //       this.initList()
  //     })
  //   }
  // }
}
</script>

<style lang="less" scoped>
.allPanel {
  background: #fff;
  padding: 18px;
}
.checkBoxItem {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-right: 8px;
  box-sizing: border-box;
}
.checkIcon {
  font-size: 20px;
  margin-right: 8px;
}
.check_1 {
  color: #99C3F8;
}
.check_2 {
  color: #4F92E6;
}
.ckItem {
  box-sizing: border-box;
  width: 100%;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  background: #efefef;
  margin: 10px;
}
.content {
  padding-left: 32px;
}
</style>