<template>
  <div>
    <div class="content">
      <div v-for='(item, index) in list' :key='index'>
        <div v-for='(ii, kk) in item' :key='kk' class="contactItem f_flex f_a_c f_j_sb">
          {{ii.originItem.userName}}
          <div>
            <i class="iconfont icon-close"  @click="removeItem(index, ii)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="contentFooter f_flex f_a_c f_j_e">
      <button @click='close' style="width: 114px" class="btn btn_radius theme_bg">确定</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: ()=> ([])
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    removeItem(index, ii) {
      this.$emit('remove', {item: ii, index: index})
      this.$global.debounce(()=> {
        if (!this.list.length) {
          this.close()
        }
      }, 300, '__contact_popup1_component__')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 13px;
}
.contentFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65px;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.2);
  box-sizing: border-box;
  padding: 0 12px;
}
.contactItem {
  height: 58px;
  border-bottom: 1px solid #eee;
}
</style>