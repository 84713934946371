<template>
  <page :footer='65' :styleWrap='{background: "#fff"}'>
    
    <div class="conactPage">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="markPanel underline">
        <img class="icon_build buildIcon" src="../../assets/icon_mark.png" alt="">
        <input type="text" class="markInput" v-model="groupName" placeholder="请输入群名称">
      </div>

      <div class="markPanel underline f_flex f_a_c f_j_sb">
        <div class="f_flex f_a_c f_g1">
          <img class="icon_build buildIcon" src="../../assets/groupname.png" alt="">
          <input type="text" v-model='word' style="width: 100%; height: 100%" class="markInput" placeholder="搜索联系人">
        </div>
        <i class="iconfont icon-search"></i>
      </div>

      <mult-choose :companyId='companyId' :word="word" ref='choose' @change='changeFn'></mult-choose>
      <div style="height: 65px"></div>
    </div>

    <div class="footer f_flex f_a_c f_j_sb" slot='footer'>
      <div class="f_flex f_a_c">
        <div class="theme_c pickCount" @click='choosePanelFn'>
          已选择：
          <span v-if='pick.pick'>{{pick.pick.count}}人</span>
          <span v-else>0人</span>
        </div>
        <i class="iconfont icon-arrow-down theme_c"></i>
      </div>
      <button style="width: 114px" @click="submitFn" class="btn btn_radius theme_bg">确定</button>
    </div>
    <popup v-model="showPopup" position="bottom" :style="{ height: '100%' }">
      <conact-content :list="pickList" @remove='removeItemFn' @close='showPopup=false'></conact-content>
    </popup>
  </page>
</template>

<script>
import multChoose from './multChoose.vue'
import { Popup} from 'vant'
import conactContent from './conactContent.vue'
import groupApi from '@/api/group'

export default {
  data: ()=> ({
    word: '',
    pick: {},
    groupName: '',
    showPopup: false,
    companyId: '0'
  }),
  components: {
    multChoose,
    conactContent,
    Popup
  },
  computed: {
    pickList() {
      if (this.pick.pick && this.pick.pick.pick.length) {
        return this.pick.pick.pick
      } else {
        return []
      }
    }
  },
  methods: {
    changeFn(e) {
      this.pick = e
      this.$set(this, 'pick', e)
    },
    choosePanelFn() {
      if (this.pick.pick && this.pick.pick.count>0) {
        this.showPopup = true
      } else {
        this.$toast('还没有选择联系人')
      }
    },
    removeItemFn(e) {
      const conact = this.$refs.choose
      const {item, index} = e
      conact.removeItem(item, index)
    },
    submitFn() {
      if (!this.groupName) {
        this.$toast('群名称不能为空')
        return
      }
      if (this.pick?.pick?.count > 0) {
        const list = this.pick.pick.pick
        const arr = []
        list.forEach(item => {
          item.forEach(ii => {
            arr.push(ii.item)
          });
        });

        const obj = {
          groupName: this.groupName,
          companyId: this.$route.query.companyId,
          userList: arr
        }
        groupApi.groupCreate(obj).then(res => {
          if (res.data) {
            this.$notify({message: '创建成功', type: 'primary'})
            window.sessionStorage.removeItem('groupInfo')
            setTimeout(() => {
              this.$router.push(`/group/detail?groupId=${res.data.bizId}&companyId=${this.$route.query.companyId}`)
            }, 200)
          }
        })
      } else {
        this.$toast('请选择联系人')
      }
    },
  },
  created() {
    setTimeout(()=> {
      this.companyId = this.$route.query.companyId
    })
  }
}
</script>

<style lang="less" scoped>
.markPanel {
  position: relative;
  padding: 15px 0;
  margin-bottom: 14px;
  .buildIcon {
    position: absolute;
  }
  .markInput {
    font-size: 16px;
    border: none;
    outline: none;
    background: transparent;
    margin-left: 16px;
    padding-left: 14px;
    width: 100%;
  }
  .icon_build {
    width: 16px;
    height: 20px;
    margin-right: 12px;
  }
}

.conactPage {
  padding: 16px;
  padding-top: 0;
}
.footer {
  height: 100%;
  padding: 0 12px;
  background: #fff;
  z-index: 9;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.2);
  .pickCount {
    font-size: 16px;
  }
  .icon-arrow-down {
    font-size: 12px;
    margin-left: 8px;
    font-weight: 500;
  }
}
</style>